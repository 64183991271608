export const DEFAULT_PROOF =
  "0x0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000";

export const DEFAULT_APPROVED_AT = Math.floor(Date.now() / 1000); // now

export const DEFAULT_VALID_UNTIL = DEFAULT_APPROVED_AT + 24 * 60 * 60; // now + 1 day

export const DEFAULT_FRACTAL_ID =
  "0x0000000000000000000000000000000000000000000000000000000000000000";

export const GOERLI_CHAIN_ID = 5;
